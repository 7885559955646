import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { initialStatePayment } from '../../../store/paymentStore'
import { calcularValorTotalPedido, getCart, getPerfile, getPessoa, savePessoa } from '../../../utils/PedidoUtil'
import { CreditCardContent } from '../../Payment/CreditCardContent'
import { FormChef } from '../../generic/FormChef'
import { removeMask } from '../../generic/InputMask'

export function DialogOnlinePayment({ onConfirm }) {
    const dispatch = useDispatch()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const { open, pessoa, formaPagamento, form } = useSelector(state => state.payment)

    useEffect(() => {
        if (!open) return

        const formCopy = { ...form }
        const pessoaJaSalva = getPessoa()

        formCopy.holderName = pessoa.nome
        formCopy.creditCardHolderInfo.name = pessoa.nome
        formCopy.creditCardHolderInfo.postalCode = pessoa.cep
        formCopy.creditCardHolderInfo.email = pessoa.email || pessoaJaSalva.email || ''
        formCopy.creditCardHolderInfo.addressNumber = pessoa.numero
        formCopy.creditCardHolderInfo.mobilePhone = pessoa.telefone.replace(/\D/g, '')

        dispatch({ type: 'UPDATE_PAYMENT', data: { form: formCopy } })
    }, [open])

    function handleClose() {
        dispatch({ type: 'UPDATE_PAYMENT', data: initialStatePayment() })
    }

    function normalizeFormData() {
        const cart = getCart()
        const empresa = getPerfile().empresa
        const formCopy = { ...form }

        formCopy.billingType = formaPagamento.tipo
        formCopy.number = form.number.replace(/\s/g, '')
        formCopy.cpfCnpj = form.cpfCnpj.replace(/\D/g, '')

        const [expiryMonth, expiryYear = ''] = form.expiryDate.split(' / ')
        formCopy.expiryMonth = expiryMonth
        formCopy.expiryYear = expiryYear

        formCopy.value = calcularValorTotalPedido(cart.pedido)
        formCopy.dueDate = new Date()

        formCopy.creditCard = {
            expiryYear,
            expiryMonth,
            ccv: formCopy.ccv,
            number: formCopy.number,
            holderName: formCopy.holderName,
        }

        formCopy.creditCardHolderInfo = {
            ...formCopy.creditCardHolderInfo,
            name: formCopy.holderName,
            cpfCnpj: formCopy.cpfCnpj,
        }

        formCopy.idEmpresa = empresa.id
        formCopy.idFormaPagamento = formaPagamento.id

        return formCopy
    }

    function saveEmail(form) {
        const pessoa = getPessoa()
        const { email } = form.creditCardHolderInfo

        if (pessoa) {
            pessoa.email = email
        }

        savePessoa(pessoa)
    }

    function handleConfirm() {
        const formCopy = normalizeFormData()

        saveEmail(formCopy)

        onConfirm(formCopy)
        handleClose()
    }

    const validator = useCallback({

        ccv: (value) => {
            if (!value) {
                return 'CVV/CVC é obrigatório'
            }

            if (value.length !== 3) {
                return 'CVV/CVC inválido'
            }

            return true
        },

        number: (value) => {
            if (!value) {
                return 'Número do cartão é obrigatório'
            }

            const rawValue = removeMask(value)
            if (rawValue.length !== 16) {
                return 'Número do cartão inválido'
            }

            return true
        },

        expiryDate: (value) => {
            if (!value) {
                return 'Data de expiração é obrigatória'
            }

            if (value.length !== 7) {
                return 'Data de expiração inválida'
            }

            const dataAtual = new Date()
            const mesAtual = dataAtual.getMonth() + 1
            const anoAtual = dataAtual.getFullYear()

            const [mesExpiracao, anoExpiracao] = value.split('/').map(Number)
            const anoExpiracaoCompleto = anoExpiracao < 100 ? 2000 + anoExpiracao : anoExpiracao

            if (anoExpiracaoCompleto < anoAtual || (anoExpiracaoCompleto === anoAtual && mesExpiracao < mesAtual)) {
                return 'A data de expiração é anterior à atual'
            }

            return true
        },

        cpfCnpj: (value) => {
            if (!value) {
                return 'CPF/CNPJ é obrigatório'
            }

            const rawValue = removeMask(value)
            if (rawValue.length !== 11 && rawValue.length !== 14) {
                return 'CPF/CNPJ inválido'
            }

            return true
        }
    }, [])

    if (!open) {
        return null
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="sm"
            fullScreen={fullScreen}
            PaperProps={{
                validator,
                component: FormChef,
                onSubmit: handleConfirm
            }}>

            <DialogTitle style={{ padding: 0, paddingLeft: '16px' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    Cartão de crédito

                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <CreditCardContent />
            </DialogContent>

            <DialogActions>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleClose}>
                    Cancelar
                </Button>

                <Button
                    type="submit"
                    color="primary"
                    variant="contained">
                    Pagar
                </Button>
            </DialogActions>

        </Dialog>
    )
}